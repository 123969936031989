.foods {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.foods > img {
  width: 64px;
  height: auto;
}

.bigRaccoon {
  width: 128px;
  max-width: 80%;
  display: block;
  margin: auto;
}

.acorn, .bud, .crayfish, .grass, .mussel, .apple, .bird, .egg, .cherry, .corn, .fish, .frog, .insect, .mouse, .reptile, .trash, .cougar, .coyote, .dog, .hawk, .owl, .snake, .person {
  /* Kind of a no-op so that I can use these classes later on with react-lineto, without polluting the global namespace. But also kind of important*/
  z-index: 1;
}