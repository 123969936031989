.container {
  width: 100%;
}

.mapContainer {
  position: relative;
}

.narrowMapContainer {
  width: 80%;
  display: block;
  height: auto;
  margin: auto;
}

.map {
  width: 100%;
  height: auto;
}

.north_america {
  position: absolute;
  width: 10%;
  left: 17.5%;
  top: 18%;
}

.europe {
  position: absolute;
  width: 8%;
  left: 48%;
  top: 13%;
}

.south_africa {
  position: absolute;
  width: 12%;
  left: 50%;
  top: 50%;
}

.south_america {
  position: absolute;
  width: 7%;
  left: 31%;
  top: 68%;
}

.cuba {
  position: absolute;
  width: 4%;
  left: 27%;
  top: 38%;
}

.japan {
  position: absolute;
  width: 3%;
  left: 84%;
  top: 26%;
}

.australia {
  position: absolute;
  width: 5%;
  left: 85%;
  top: 75%;
}

.china {
  position: absolute;
  width: 8%;
  left: 75%;
  top: 23%;
}

.east_middle_east_area {
  position: absolute;
  width: 5%;
  left: 61%;
  top: 25%;
}

.northern_russia {
  position: absolute;
  width: 5%;
  left: 70%;
  top: 5%;
}

.northern_afria {
  position: absolute;
  width: 5%;
  left: 50%;
  top: 35%;
}

.northern_south_america {
  position: absolute;
  width: 5%;
  left: 29%;
  top: 53%;
}

.northern_canada {
  position: absolute;
  width: 5%;
  left: 27%;
  top: 5%;
}

.india {
  position: absolute;
  width: 3%;
  left: 69.5%;
  top: 37.5%;
}

.saudiArabiaArea {
  position: absolute;
  width: 3%;
  left: 60%;
  top: 37%;
}

.northern_australia {
  position: absolute;
  width: 3%;
  left: 85%;
  top: 65%;
}

.indonesian_area {
  position: absolute;
  width: 3%;
  left: 80%;
  top: 55%;
}

/* img(1) -> the map, img(2) -> north america */
.invasive > img:not(:first-child):not(:nth-child(2)) {
  filter: invert(24%) sepia(99%) saturate(6982%) hue-rotate(1deg) brightness(101%) contrast(122%);
}

/* This is my new favorite hack using css grids */
.noRaccoon {
  display: grid;
}

.noRaccoon > img {
  grid-column: 1;
  grid-row: 1;
}